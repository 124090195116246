/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { useAppDispatch } from '../../../store/hooks';

import './css/spid-sp-access-button.css'

import spidLogo from '../spid/img/spid-ico-circle-bb.png'
import arubaIdSvg from "./img/spid-idp-arubaid.svg"
import infoCertIdSvg from "./img/spid-idp-infocertid.svg"
import intesaIdSvg from "./img/spid-idp-intesaid.svg"
import lepidaIdSvg from "./img/spid-idp-lepidaid.svg"
import namirialIdSvg from "./img/spid-idp-namirialid.svg"
import posteIdSvg from "./img/spid-idp-posteid.svg"
import sielteIdSvg from "./img/spid-idp-sielteid.svg"
import spidItaliaSvg from "./img/spid-idp-spiditalia.svg"
import timIdSvg from "./img/spid-idp-timid.svg"
import teamSystemIdSvg from "./img/spid-idp-teamsystemid.svg"
import { AsyncThunk } from '@reduxjs/toolkit';
import { authURL } from '../../../utils/utilconst';

interface IDP {
  entity_id: string,
  organization_name: string,
  logo_uri?: string,
  registry_link?: string
}

interface IdpEntry {
  identifier: string;
  entityId: string;
  name: string;
  imageUrl: string;
}

interface ExtraInfo {
  title: string;
  url: string;
}

interface SpidProviders {
  identityProviders: IdpEntry[];
  extraInfo: ExtraInfo[];
}

interface AuthRequest {
  xmlAuthRequest: string;
  destinationUrl: string;
}

interface SpidAccessButtonProps {
  spidAssertionConsumerServiceIndex?: number
  spidProvidersListApi?: () => any
  getAuthRequest: AsyncThunk<AuthRequest, any, any>
  providersList: SpidProviders | null
  samlAuthRequest: AuthRequest | null
  nomeHost?: string | null
}

const SpidAccessButton = (props: SpidAccessButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { spidAssertionConsumerServiceIndex, spidProvidersListApi, getAuthRequest, providersList: idps, samlAuthRequest, nomeHost } = props;

  const dispatch = useAppDispatch();

  const formRef = React.useRef<HTMLFormElement>(null);

  const [show, setShow] = React.useState<boolean>(false);
  const [idpList, setIdpList] = React.useState<IdpEntry[]>([]);

  const QUERY_URL = "https://registry.spid.gov.it/entities-idp?&output=json&custom=info_display_base";
  const IDPS = React.useMemo(() => {
    return [
      {
        organization_name: "ArubaPEC S.p.A.",
        entity_id: "https://loginspid.aruba.it",
        logo_uri: arubaIdSvg
      },
      {
        organization_name: "InfoCert S.p.A.",
        entity_id: "https://identity.infocert.it",
        logo_uri: infoCertIdSvg
      },
      {
        organization_name: "IN.TE.S.A. S.p.A.",
        entity_id: "https://spid.intesa.it",
        logo_uri: intesaIdSvg
      },
      {
        organization_name: "Lepida S.p.A.",
        entity_id: "https://id.lepida.it/idp/shibboleth",
        logo_uri: lepidaIdSvg
      },
      {
        organization_name: "Namirial",
        entity_id: "https://idp.namirialtsp.com/idp",
        logo_uri: namirialIdSvg
      },
      {
        organization_name: "Poste Italiane SpA",
        entity_id: "https://posteid.poste.it",
        logo_uri: posteIdSvg
      },
      {
        organization_name: "Sielte S.p.A.",
        entity_id: "https://identity.sieltecloud.it",
        logo_uri: sielteIdSvg
      },
      {
        organization_name: "Register.it S.p.A.",
        entity_id: "https://spid.register.it",
        logo_uri: spidItaliaSvg
      },
      {
        organization_name: "TI Trust Technologies srl",
        entity_id: "https://login.id.tim.it/affwebservices/public/saml2sso",
        logo_uri: timIdSvg
      },
      {
        organization_name: "TeamSystem s.p.a.",
        entity_id: "https://spid.teamsystem.com/idp",
        logo_uri: teamSystemIdSvg
      }
    ]
  }, []);

  const providerTest: IDP[] = React.useMemo(() => {
    return [
      {
        entity_id: 'https://demo.spid.gov.it',
        organization_name: 'SPID Demo',
      },
      {
        entity_id: 'https://demo.spid.gov.it/validator',
        organization_name: 'SPID Demo (Validator Mode)',
      },
      // {
      //   entity_id: 'https://validator.spid.gov.it/login',
      //   organization_name: 'SPID Validator',
      //   logo_uri: null,
      //   registry_link: null
      // },
      // {
      //   entity_id: 'idp.spid.gov.it',
      //   organization_name: 'AgID IdP test',
      //   logo_uri: null,
      //   registry_link: null
      // },
    ]
  }, []);

  const spid_populate = React.useCallback(() => {
    if (spidProvidersListApi) {
      // Execute custom idp registry api
      dispatch(spidProvidersListApi())
    } else {
      // Execute default/official idp registry api
      const convert = (idp: IDP): IdpEntry => {
        return {
          identifier: idp.entity_id,
          entityId: idp.entity_id,
          name: idp.organization_name,
          imageUrl: idp.logo_uri ?? '',
        }
      };

      fetch(QUERY_URL)
        .then(response => response.json())
        .then(idps => {
          const converted = [...idps, ...providerTest].map(convert);
          setIdpList(converted.sort(() => Math.random() - 0.5));
        })
        .catch(error => {
          console.log('Error during fetch: ' + error.message);
          setIdpList(IDPS.map(convert).sort(() => Math.random() - 0.5));
        });
    }
  }, [IDPS, dispatch, providerTest, spidProvidersListApi]);

  /**
   * Fetch providers list
   */
  React.useEffect(() => {
    spid_populate();
  }, [spid_populate]);

  /**
   * Redirect to IDP
  */
  React.useEffect(() => {
    if (samlAuthRequest && formRef.current)
      formRef.current.submit()
  }, [samlAuthRequest]);

  React.useEffect(() => {
    if (idps && idps.identityProviders?.length > 0) {
      const idpsRandomSort = [...idps.identityProviders].sort(() => Math.random() - 0.5)
      setIdpList(idpsRandomSort);
    }
  }, [idps]);

  const toggleSpidList = () => {
    setShow(state => !state);
  }

  const generateIdpList = React.useCallback((idps: IdpEntry[]) => {
    // const fetchData = (entityId: string) => {
    //   dispatch(getAuthRequest({
    //     entityId,
    //     assertionConsumerServiceIndex: spidAssertionConsumerServiceIndex ?? 0
    //   }))
    // };

    return idps.map(idp => {
      return (
        <li key={'idp-' + idp.entityId} data-idp={idp.entityId} className='spid-idp-button-link'>
          <a href={authURL + '/auth-spid-redirect?entityId=' +idp.entityId+ '&assertionConsumerServiceIndex=' +spidAssertionConsumerServiceIndex+'&nomeHost='+nomeHost}
          //  onClick={(event) => {
          //   event.preventDefault();
          //   fetchData(idp.entityId);
          // }}
          >
            <span className="spid-sr-only">{idp.name}</span>
            <img src={idp.imageUrl} alt={idp.name} />
          </a>
        </li>
      );
    })
  }, [spidAssertionConsumerServiceIndex,nomeHost]);

  return (
    <div className='container'>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        className="italia-it-button italia-it-button-size-m button-spid"
        spid-idp-button="#spid-idp-button-medium-get"
        aria-expanded="false"
        onClick={toggleSpidList}
      >
        <span className="italia-it-button-icon">
          <img src={spidLogo}
            alt=""
            onError={(e) => {
              e.currentTarget.src = '../spid/img/spid-ico-circle-bb.png';
              e.currentTarget.onerror = null;
            }}
          />
        </span>
        <span className="italia-it-button-text">Entra con SPID</span>
      </a>
      {
        show &&
        <div id="spid-idp-button-medium-get" className="spid-idp-button spid-idp-button-tip spid-idp-button-relative">
          <ul id="spid-idp-list-medium-root-get" className="spid-idp-button-menu" data-spid-remote
            aria-labelledby="spid-idp">
            {
              generateIdpList(idpList)
            }
            <li><a className="dropdown-item" href="https://www.spid.gov.it">Maggiori informazioni</a></li>
            <li><a className="dropdown-item" href="https://www.spid.gov.it/richiedi-spid">Non hai SPID?</a></li>
            <li><a className="dropdown-item" href="https://www.spid.gov.it/serve-aiuto">Serve aiuto?</a></li>
          </ul>
        </div>
      }
      <div style={{ display: 'none' }}>
        {
          samlAuthRequest &&
          <form ref={formRef} method="POST" action={samlAuthRequest.destinationUrl}>
            <input type="hidden" name="SAMLRequest" value={btoa(samlAuthRequest.xmlAuthRequest)} />
            <input type="hidden" name="RelayState" value="kjhlsdhfk" />
            <input type="submit" value="Invia" />
          </form>
        }
      </div>
    </div>
  )
}

export default SpidAccessButton;