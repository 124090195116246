interface MyThemes {
  [key: string]: Object
}
export const themes: MyThemes =
{
  consorzioinnovo: {
    palette: {
      common: {
        black: "#000",
        white: "#fff"
      },
      background: {
        paper: "#fff",
        default: "#fafafa"
      },
      primary: {
        main: "#6699ff",
        contrastText: "#fff"
      },
      secondary: {
        light: "#ff4081",
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff"
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff"
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      }
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(',')
    },
    leftImage: {
      backgroundImage: 'url(./assets/loginbackgrounds/consorzioinnovo.jpg)'
    }
  },
  ptv: {
    palette: {
      common: {
        black: "#000",
        white: "#fff"
      },
      background: {
        paper: "#fff",
        default: "#fafafa"
      },
      primary: {
        main: "#005c37",
        contrastText: "#fff"
      },
      secondary: {
        light: "#ff4081",
        main: "#00833f",
        dark: "#c51162",
        contrastText: "#fff"
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff"
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      }
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(',')
    }
  }
};
export default themes;