import { AppBar, Box, Button, Link, makeStyles, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { StatusEnum } from "../../models/Utils";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { checkJsonWebToken, doLogin } from "../../store/slices/authInfoSlice";
import { applicationsPath, forgotPasswordPath, loginPath } from "../../utils/paths";
import LeftImage from "../components/LeftImage/LeftImage";
import LoadingSvg from "../svgs/LoadingSvg";
import { isToken2FA, setCookie } from "../../utils/utilfunctions";
import { cookiePortal, cookieRedirect, secondLevelDomain } from "../../utils/utilconst";
import { openSnackBarError } from "../../store/slices/snackBarSlice";
import SpidAccessButton from "../components/spid/SpidAccessButton";
import Cie from "../components/cie/Cie";
import { fetchAuthRequestSpid, fetchSpidProvidersList } from '../../store/slices/spidSlice';
import GTModal from "../components/modal/GTModal";
import TwoFAForm from "../components/2FA/TwoFAForm";
import { CheckJWTResponse, TokenResponse } from "../../models/AuthModels";

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: "pointer"
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: TabIndex;
  value: TabIndex;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

enum TabIndex {
  USERNAME,
  SPID
}

const PageLogin = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [called, setCalled] = useState<boolean>(false);

  const organizzazione = useAppSelector(state => state.nomeHost.nomeHost);
  const authStatus = useAppSelector(state => state.authInfo.status);

  const samlAuthRequestSpid = useAppSelector(state => state.spid.authRequest);
  const idps = useAppSelector(state => state.spid.spidProviders);

  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [invalidBackend, setInvalidBackend] = useState<boolean>(false);

  const [isOpenTableModal, setIsOpenTableModal] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpenTableModal(false);
  }

  /**
   * Handle query strings to login
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const paramsCount = (urlParams as any).size;  // determines if > 0 then /login is accessed externally/manually (spid or typing query string)

    if (paramsCount > 0) {
      const token = urlParams.get('token');
      const error = urlParams.get('error');
      const clearUrl = () => history.push(loginPath);

      if (error) {
        dispatch(openSnackBarError(error));
        clearUrl();
      } else if (token !== null && token !== undefined) {
        interface CheckJWTResponseExtended extends CheckJWTResponse {
          token?: string
        }

        dispatch(checkJsonWebToken(token))
          .then(resp => resp.payload)
          .then((data) => {
            const _data = data as CheckJWTResponseExtended;
            if (_data?.isValid) {
              setCookie(cookiePortal, token, secondLevelDomain, 60 * 60 * 1000);
              setCookie(cookieRedirect, window.location.host, secondLevelDomain)  // allows redirection when authenticated using spid
              history.push(applicationsPath);
            } else {
              clearUrl();
            }
          })
          .catch(() => {
            clearUrl();
          });
      } else {
        clearUrl();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setInvalidEmail(false);
    setCalled(true);
    dispatch(doLogin({ username: email, password: password }))
      .then(data => {
        const _data = data?.payload as TokenResponse
        const token = _data?.['token'];
        if (isToken2FA(token)) {
          setIsOpenTableModal(true);
        }
        else {
          history.push(applicationsPath);
        }
      });
  }

  useEffect(() => {
    if (called && authStatus === StatusEnum.Succeeded) {
      setCalled(false);
    } else if (called && authStatus === StatusEnum.Failed) {
      setCalled(false);
      setInvalidBackend(true);
    }
  }, [called, authStatus]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    invalidBackend && setInvalidBackend(false);
    invalidEmail && setInvalidEmail(false);
    if (event.target.name === 'email') setEmail(event.target.value);
    else setPassword(event.target.value);
  };

  const isLoginDisabled = () => {
    return (email.length < 3 || password.length === 0);
  }

  const clear = useCallback(() => {
    setInvalidBackend(false);
    setInvalidEmail(false);
    setPassword('');
    setEmail('');
    setCalled(false);
  }, []);

  useEffect(() => {
    return () => {
      clear()
    };
  }, [clear]);

  useEffect(() => {
    clear();
  }, [i18n.language, clear]);

  const [tabValue, setTabValue] = useState<TabIndex>(TabIndex.USERNAME);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <LeftImage>
      {
        isOpenTableModal &&
        <GTModal
          openState={{
            open: isOpenTableModal,
            setOpen: setIsOpenTableModal
          }}
          handleClose={handleClose}
        >
          <TwoFAForm username={email} />
        </GTModal>
      }

      <Typography component="h1" variant="h5">
        {t('signin')}
      </Typography>

      <Typography component="h3" variant="h6" color="error">
        {invalidEmail ? t('invalidEmail') : (invalidBackend && t('notValidSignin'))}&nbsp;
      </Typography>

      {/* Login Box */}
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Utente e Password"  {...a11yProps(0)} />
            {
              organizzazione?.spidAbilitato &&
              <Tab label="Spid / CIE" {...a11yProps(1)} />
            }
          </Tabs>
        </AppBar>

        {/* Username and password, Active Directory */}
        <TabPanel value={tabValue} index={0}>
          {
            authStatus === StatusEnum.Loading ? <LoadingSvg color="primary" width={200} /> : (
              <>
                <form className={classes.form} noValidate onSubmit={login}>
                  <TextField
                    error={invalidBackend || invalidEmail}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('userName')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleInputChange}
                  />
                  <TextField
                    error={invalidBackend}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleInputChange}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isLoginDisabled()}
                  >
                    {t('signin')}
                  </Button>
                  <Link onClick={() => history.push(forgotPasswordPath)} variant="body2" className={classes.link}>
                    {t('forgotPassword')}
                  </Link>
                </form>
              </>
            )
          }
        </TabPanel>

        {/* SPID */}
        {
          organizzazione?.spidAbilitato &&
          <>
            <TabPanel value={tabValue} index={1}>
              <Box display='flex' flexWrap='wrap-reverse'>
                <Box flex={1} mt={2}>
                  <SpidAccessButton
                    samlAuthRequest={samlAuthRequestSpid}
                    spidAssertionConsumerServiceIndex={organizzazione.spidAssertionConsumerServiceIndex}
                    getAuthRequest={fetchAuthRequestSpid}
                    spidProvidersListApi={fetchSpidProvidersList}
                    providersList={idps}
                    nomeHost={organizzazione.nomeHost}
                  />
                </Box>
                <Box flex={1} mt={2}>
                  <Cie cieAssertionConsumerServiceIndex={organizzazione.spidAssertionConsumerServiceIndex} />
                </Box>
              </Box>
            </TabPanel>
          </>
        }
      </div>
    </LeftImage>
  );
}
export default PageLogin;