import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { useAppSelector } from "../../../store/hooks";
import LanguageToggle from "../LanguageToggle";
import useStyles from "./useStyle";

const LeftImage: React.FC = ({ children }) => {
  const classes = useStyles();
  const nomeHost = useAppSelector(state => state.nomeHost.nomeHost);

  return <>
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image}
        style={{ backgroundImage: 'url(./assets/loginbackgrounds/' + (nomeHost ? nomeHost.skinTheme : "default") + '.jpg)' }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Grid container justify="flex-end">
            <Grid item>
              <LanguageToggle />
            </Grid>
          </Grid>
          <img className={classes.logo} src={"./assets/logos/" + (nomeHost ? nomeHost.skinTheme.toLowerCase().replace(' ', '') : "default") + ".png"} alt={nomeHost?.skinTheme ?? 'logo'} />
          {children}
        </div>
      </Grid>
    </Grid>
  </>
}
export default LeftImage;